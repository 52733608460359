import React from 'react'
import {PopupButton} from '@typeform/embed-react'

import {Helmet} from 'react-helmet'

import FeatureCard31 from '../components/feature-card31'
import './home.css'


const Home = (props) => {
    return (
        <div className="home-container">
            <Helmet>
                <title>Easy Auto Hub</title>
                <meta property="og:title" content="Easy Auto Hub"/>
            </Helmet>
            <div className="home-hero">
                <div className="home-hero1">
                    <div className="home-container1">
                        <h1 className="home-hero-heading heading1">Easy Auto
                            Hub</h1>
                        <span className="home-hero-sub-heading">
              Einfach Deinen Werkstatttermin Buchen
            </span>
                        <div className="home-btn-group">
                            <PopupButton id="xoQNrMK8" style={{fontSize: 20}}
                                         className="home-hero-button1 button">
                                Termin Vereinbaren
                            </PopupButton>

                        </div>
                    </div>
                </div>
            </div>
            <div className="home-details">
                <div className="home-details1">
                    <div className="home-container2">
                        <h2 className="home-details-heading heading2">
                            Deine Wartung, vereinfacht.
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: ' ',
                                }}
                            />
                        </h2>
                        <span className="home-details-sub-heading">
              Du kennst das Problem: Dein Auto benötigt dringend Wartung, doch
              es scheint unmöglich, einen freien Termin zu finden. Mit
              EasyAutoHub ist das ab sofort Vergangenheit. Wir arbeiten eng mit
              Werkstätten in deiner Nähe zusammen und bieten dir eine
              unkomplizierte Lösung. Finde und buche freie Termine ganz einfach,
              ohne lästige Anrufe oder E-Mails. Dein Auto verdient die beste
              Pflege – und wir machen es so einfach wie nie zuvor.
            </span>
                    </div>
                    <img
                        alt="image"
                        src="/easyautohublog-400h.png"
                        className="home-details-image"
                    />
                </div>
            </div>
            <div className="home-features">
                <h1 className="home-text">
                    <span>Erlebe wie einfach Auto geht.</span>
                    <br></br>
                </h1>
                <div className="home-container3">
                    <FeatureCard31
                        rootClassName="rootClassName"
                        description="Mit EasyAutoHub ist die Terminvereinbarung ein Kinderspiel. Finde und buche freie Termine bei Werkstätten in deiner Nähe ganz unkompliziert."
                    ></FeatureCard31>
                    <FeatureCard31
                        rootClassName="rootClassName3"
                        title="Dokumentation"
                        description="Verliere nie wieder den Überblick über die Reparaturhistorie deines Fahrzeugs. Mit EasyAutoHub kannst du ganz einfach auf deine Wartungs- und Reparaturdokumente zugreifen."
                    ></FeatureCard31>
                    <FeatureCard31
                        rootClassName="rootClassName5"
                        title="Planbarkeit"
                        description="Behalte die Wartung deines Autos im Griff. Trage die Daten deines Fahrzeugs ein und erhalte klare Informationen darüber, wann Service erforderlich ist und welche Arbeiten durchgeführt werden müssen"
                    ></FeatureCard31>
                </div>
            </div>
        </div>
    )
}

export default Home
